import {
  SellernoteAppLanguage,
  SellernoteAppRegion,
} from "../types/common/common";

import { APP_CURRENT_LANGUAGE, APP_NAME, APP_REGION } from "../constants";

/**
 * 구글 맵 라이브러리를 초기화 할때 공통으로 사용하는 옵션.
 * - 이후에도 초기화할때도 동일한 설정을 사용해야함(어떤 컴포넌트에서 초기화 될 지 알 수 없으므로)
 */

const GOOGLE_MAP_COMMON_BOOT_OPTION: {
  apiKey: string;
  libraries: (
    | "drawing"
    | "geometry"
    | "localContext"
    | "marker"
    | "places"
    | "visualization"
  )[];
  language: SellernoteAppLanguage;
  region: SellernoteAppRegion;
} = {
  apiKey:
    APP_NAME === "shipda-admin"
      ? process.env.REACT_APP_PUBLIC_GOOGLE_MAP_API_KEY!
      : process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
  libraries: ["places", "marker"],
  language: APP_CURRENT_LANGUAGE,
  region: APP_REGION,
};

export { GOOGLE_MAP_COMMON_BOOT_OPTION };
