import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { checkCanEditBidItem } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import ItemUpdateModal from "../../components/ItemUpdateModal";

import MsdsDownload from "../../../MsdsDownload";

function LCLAndAIRItemList({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);

  const handleItemUpdateModalOpen = () => {
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setShowsItemUpdateModal(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Card>
          <CardHeader
            sx={{ backgroundColor: `${grey[100]}` }}
            action={
              APP_NAME === "shipda-admin" && (
                <Button
                  disabled={checkCanEditBidItem(
                    bidDetail.projectStatus,
                    currentAdminAuthInfo?.authority
                  )}
                  onClick={handleItemUpdateModalOpen}
                >
                  수정
                </Button>
              )
            }
            title={
              <Typography variant="subtitle2" component="div">
                {getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply)}
              </Typography>
            }
          />

          {bidDetail.bidItems.map((item, index) => {
            return (
              <CardContent key={index}>
                <Grid container alignItems="baseline" spacing={1}>
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={4}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        품명 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={4}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        포장유형 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {item.packingType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={4}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        부피 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {item.horizontal
                            ? `${item.cbm}CBM(${item.horizontal}x${item.vertical}x${item.height}) ${item.volumeUnit}`
                            : `${item.cbm}CBM`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={3}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        중량 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {item.weight}
                          {item.weightUnit}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={2}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        수량 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {item.quantity}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={4}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        위험물 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {changeBooleanValueToKr(item.isDangerous)}
                        </Typography>
                      </Grid>

                      {APP_NAME === "shipda-admin" &&
                        bidDetail.attachments.length > 0 && (
                          <Grid item>
                            <MsdsDownload
                              bidId={bidDetail.id}
                              attachments={bidDetail.attachments}
                            />
                          </Grid>
                        )}
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={3}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        냉동/냉장여부 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {changeBooleanValueToKr(item.needRefrigeration)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      alignItems="baseline"
                      xs={3}
                      spacing={1}
                    >
                      <Typography variant="body2" component="div">
                        2단적재 :
                      </Typography>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {changeBooleanValueToKr(item.canStack)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            );
          })}
        </Card>
      </Grid>

      {showsItemUpdateModal && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          showsItemUpdateModal={showsItemUpdateModal}
          onItemUpdateModalClose={handleItemUpdateModalClose}
        />
      )}
    </Grid>
  );
}

export default LCLAndAIRItemList;
