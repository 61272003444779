import { useCallback } from "react";
import { Control, useFieldArray, UseFormSetValue } from "react-hook-form";
import { Button, Divider, Grid } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { getRandomNumber } from "@sellernote/_shared/src/utils/common/number";
import { calculateVATPrice } from "@sellernote/_shared/src/utils/forwarding/trello";

import FormList from "./FormList";
import PurchasePriceSummary from "./PurchasePriceSummary";

function PurchaseRequestForm({
  withdrawalData,
  control,
  currency,
  companyType,
  setValue,
  isNewCurrency,
  saveExchangeRate,
  exchangeRateList,
  withdrawalItemList,
  trelloDetailData,
}: {
  withdrawalData: WithdrawalFormDetail[];
  control: Control<ApplyBidFormData>;
  currency: Currency;
  companyType: PartnerBusinessArea;
  setValue: UseFormSetValue<ApplyBidFormData>;
  isNewCurrency: boolean;
  saveExchangeRate: number;
  exchangeRateList: ExchangeRate[];
  withdrawalItemList: string[];
  trelloDetailData: TrelloBidDetail;
}) {
  const { remove, insert } = useFieldArray({
    control,
    name: "withdrawalData",
  });

  const getPriceCalculatedAtExchangeRate = useCallback(
    (row: WithdrawalFormDetail) => {
      const currencyIndex = exchangeRateList.findIndex((v) => {
        return (
          v.currency === row.currency ||
          (v.currency === "CNY" && row.currency === "CNY")
        );
      });
      if (row.currency === "KRW") {
        return Number((row.unitPrice * row.amount).toFixed(0));
      }

      if (isNewCurrency) {
        const itemPrice = Number(
          (row.unitPrice * row.amount * saveExchangeRate).toFixed(0)
        );
        return itemPrice;
      }

      if (currencyIndex !== -1) {
        const itemPrice = Number(
          (
            row.unitPrice *
            row.amount *
            exchangeRateList[currencyIndex].rate
          ).toFixed(0)
        );
        return itemPrice;
      }
      return row.unitPrice;
    },
    [exchangeRateList, isNewCurrency, saveExchangeRate]
  );

  const getTotalPrice = useCallback(
    (formData: WithdrawalFormDetail) => {
      let totalPrice = 0;

      if (companyType === "foreign") {
        totalPrice = 0;
      } else {
        totalPrice = getPriceCalculatedAtExchangeRate(formData);
      }

      return totalPrice;
    },
    [companyType, getPriceCalculatedAtExchangeRate]
  );

  const getVATPrice = useCallback(
    (formData: WithdrawalFormDetail, totalPrice: number) => {
      let VATPrice = 0;

      // 비과세 항목은 0으로 리턴한다.
      if (
        formData.name === "WFG" ||
        formData.name === "PFS" ||
        formData.name === "보험료"
      ) {
        return VATPrice;
      }

      if (companyType === "foreign") {
        VATPrice = 0;
      } else {
        VATPrice = calculateVATPrice(formData, totalPrice, companyType);
      }

      if (!VATPrice) {
        VATPrice = 0;
      }
      return VATPrice;
    },
    [companyType]
  );

  const getFinalPrice = useCallback(
    (formData: WithdrawalFormDetail, totalPrice: number, VATPrice: number) => {
      let finalPrice = 0;

      if (companyType === "foreign") {
        finalPrice = getPriceCalculatedAtExchangeRate(formData);
      } else {
        finalPrice = totalPrice + VATPrice;
      }

      return finalPrice;
    },
    [companyType, getPriceCalculatedAtExchangeRate]
  );

  const checkIsVAT = useCallback(
    (name: string | null) => name?.includes("VAT") ?? false,
    []
  );

  const getNewFormDataItem = useCallback(
    (formData: WithdrawalFormDetail): WithdrawalFormDetail => {
      // 부가세, 공급가, 합계만 수정을 하는 지 체크
      // originData는 수정 하기 전 데이터인데 전체 수정 상태에서 항목 추가를 할 경우 없는 경우가 있어 예외 처리

      const isVAT = checkIsVAT(formData.name);

      const totalPrice = getTotalPrice(formData);

      const VATPrice = getVATPrice(formData, totalPrice);

      const finalPrice = getFinalPrice(formData, totalPrice, VATPrice);

      const newFormData = {
        ...formData,
        totalPrice,
        vatPrice: VATPrice,
        finalPrice,
        isVAT,
      };

      return newFormData;
    },
    [checkIsVAT, getFinalPrice, getTotalPrice, getVATPrice]
  );

  return (
    <Grid container>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => {
            if (withdrawalData) {
              return insert(withdrawalData.length + 1, {
                id: getRandomNumber(),
                currency,
                unitPrice: 0,
                itemUnitMeasurement: "",
                amount: 0,
                totalPrice: 0,
                vatPrice: 0,
                finalPrice: 0,
                isVAT: false,
                name: "",
                note: "",
              });
            }
            return;
          }}
        >
          추가
        </Button>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => {
            remove();
          }}
        >
          초기화
        </Button>
      </Grid>

      <Grid
        item
        container
        columns={24}
        alignItems="center"
        sx={{ backgroundColor: grey[100], height: "32px" }}
      >
        <Grid item xs={5}>
          항목
        </Grid>

        <Grid item xs={2}>
          화폐
        </Grid>

        <Grid item xs={2}>
          단가
        </Grid>

        <Grid item xs={3.5}>
          단위
        </Grid>

        <Grid item xs={1.5}>
          수량
        </Grid>

        {companyType !== "foreign" && (
          <Grid item xs={2.5}>
            공급가
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            부가세
          </Grid>
        )}

        <Grid item xs={2}>
          합계 (외화)
        </Grid>

        <Grid item xs={2.5}>
          합계 (KRW)
        </Grid>

        <Grid item xs={1}>
          삭제
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: blue[700] }} component={"div"} />
      </Grid>

      {withdrawalData.map((v, index) => {
        return (
          <FormList
            key={v.id}
            control={control}
            formListIndex={index}
            formData={v}
            remove={remove}
            setValue={setValue}
            getNewFormDataItem={getNewFormDataItem}
            withdrawalItemList={withdrawalItemList}
            exchangeRateList={exchangeRateList}
            companyType={companyType}
            trelloDetailData={trelloDetailData}
          />
        );
      })}

      <PurchasePriceSummary
        currency={currency}
        companyType={companyType}
        withdrawalData={withdrawalData}
      />
    </Grid>
  );
}

export default PurchaseRequestForm;
