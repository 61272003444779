import { css, FlattenInterpolation } from "styled-components";

import {
  MOBILE_MAX_WIDTH,
  MY_PAGE_FOOTER_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from "../constants/common/common";
import { COLOR } from "../stylesToMoveToV1/constants";

/** 모바일 size용 스타일 */
export function mobile(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${MOBILE_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * 태블릿 size용 스타일
 */
export function tablet(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${TABLET_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * 마이페이지 > 푸터 size용 스타일
 * TODO: 푸터 리뉴얼시 삭제 예정
 */
export function myPage(styles: FlattenInterpolation<any>) {
  return css`
    @media (max-width: ${MY_PAGE_FOOTER_MAX_WIDTH}px) {
      ${styles}
    }
  `;
}

/**
 * 스크롤 바를 표시하지 않는다
 */
export function hideScrollBar() {
  return css`
    &::-webkit-scrollbar {
      display: none;
    }
  `;
}

/**
 * 세로 스크롤바를 항상 표시한다
 */
export function showVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar {
      display: block;
      width: 10px;
      padding: 4px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: ${COLOR.grayScale_300};
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;

      &:hover {
        cursor: pointer;
      }
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  `;
}

/**
 *
 * showVerticalScrollBarAlways의 효과를 무효화
 */
export function disableSettingOfShowVerticalScrollBarAlways() {
  return css`
    &::-webkit-scrollbar:vertical {
      display: none;
    }
  `;
}

/**
 * 말줄임표 처리
 */
export const formatEllipsis = () =>
  css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

/**
 * \n 으로 텍스트의 줄바꿈을 스타일링 하는 경우 사용
 *
 * i18n의 <br /> 태그만 사용하는 경우 공통 번역문에 사이드 이펙트가 발생해 \n 으로 처리하는 경우 선택적 개행을 사용함
 */
export const applyNewLine = () => css`
  white-space: pre-line;
`;
