const FORWARDING_TABLE_PER_PAGE_KEY = "forwardingTablePerPage";

const IMPORT_SHIPMENT_INCOTERMS_LIST = [
  "FOB",
  "EXW",
  "FCA",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
];

export { FORWARDING_TABLE_PER_PAGE_KEY, IMPORT_SHIPMENT_INCOTERMS_LIST };
