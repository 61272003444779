import { Box, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import {
  ExportTrelloList,
  PartnerTrelloList,
  ScheduleUpdatedItems,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import CustomStatusChip from "./components/CustomStatusChip";

import koreaFlag from "../../images/regionFlag/korea.webp";
import singaporeFlag from "../../images/regionFlag/singapore.webp";

const getTrelloCardTitle = (
  cardData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: 14,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {cardData.team.company ?? "-"}
        </Typography>

        <img
          src={cardData.region === "KR" ? koreaFlag : singaporeFlag}
          alt={cardData.region === "KR" ? "KR" : "SG"}
          style={{ width: "22px", height: "15px" }}
        />
      </div>

      <Typography
        sx={{
          fontSize: 12,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        ({cardData.team.name ?? "-"})
      </Typography>
    </Box>
  );
};

const getShipmentSchedule = (
  scheduleUpdatedItems: ScheduleUpdatedItems[] | null,
  ATA: string | null,
  ATD: string | null,
  ETA: string | null,
  ETD: string | null
) => {
  const highlightsText = (target: ScheduleUpdatedItems) => {
    if (!scheduleUpdatedItems?.length) return {};

    if (scheduleUpdatedItems.includes(target)) {
      return { color: blue[500] };
    }

    return {};
  };

  const COMMON_STYLE = {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: -0.5,
  } as const;

  const showsSchedule = Boolean(ATD || ETD);

  if (!showsSchedule) return null;

  return (
    <>
      <span style={{ ...COMMON_STYLE, ...highlightsText("ETD") }}>
        {toFormattedDate(ATD, "YY-MM-DD") || toFormattedDate(ETD, "YY-MM-DD")}
      </span>

      {`${" "}~${" "}`}

      <span style={{ ...COMMON_STYLE, ...highlightsText("ETA") }}>
        {toFormattedDate(ATA, "YY-MM-DD") || toFormattedDate(ETA, "YY-MM-DD")}
      </span>
    </>
  );
};

const getTrelloCardCommonBody = (
  trelloListData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <>
      <Typography sx={{ fontSize: 12 }} variant="body2">
        {trelloListData.management.memo}
      </Typography>

      <Typography
        variant="body2"
        component={"div"}
        sx={{ fontWeight: "bold", color: grey[700] }}
      >
        {trelloListData.id}{" "}
        {trelloListData?.poNumber &&
          trelloListData.poNumber.length > 0 &&
          `${
            trelloListData.poNumber.length > 1
              ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
              : `/ ${trelloListData.poNumber[0]}`
          }`}
      </Typography>

      {getShipmentSchedule(
        trelloListData.management.scheduleUpdatedItems,
        trelloListData.management.ATA,
        trelloListData.management.ATD,
        trelloListData.management.ETA,
        trelloListData.management.ETD
      )}
    </>
  );
};

const getTrelloCardCustomsChip = (
  cardData: TrelloBidList | PartnerTrelloList
) => {
  return <CustomStatusChip status={cardData.management.customsStatus} />;
};

const getServiceType = ({ pathname }: { pathname: string }) => {
  if (pathname.includes("consolidation")) return "consolidation";

  if (pathname.includes("export")) return "export";

  return "import";
};

export {
  getTrelloCardTitle,
  getTrelloCardCommonBody,
  getTrelloCardCustomsChip,
  getServiceType,
};
