import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Grid } from "@mui/material";

import { ADMIN_BID_CONTAINER_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { ShipmentItem } from "@sellernote/_shared/src/types/forwarding/adminBid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import NumericFormatWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/NumericFormatWithReactHookForm";
import SwitchWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
function FCLItemForm({
  onShipmentItemWithoutRecalculationUpdate,
}: {
  onShipmentItemWithoutRecalculationUpdate: () => void;
}) {
  const { control, handleSubmit } = useFormContext<{
    bidItems: Partial<ShipmentItem>[];
  }>();

  const { remove, append, fields } = useFieldArray({
    control,
    name: "bidItems",
  });

  const handleRemoveForm = (formIndex: number) => {
    remove(formIndex);
  };

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      component="form"
      onSubmit={handleSubmit(onShipmentItemWithoutRecalculationUpdate)}
    >
      {fields.map((FCLItem, index) => {
        return (
          <Grid item container key={FCLItem.id}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={3}>
                <TextFieldWithReactHookForm
                  name={`bidItems.${index}.name`}
                  control={control}
                  label="품명"
                  required={true}
                />
              </Grid>

              <Grid item xs={3}>
                <AutoCompleteWithReactHookForm
                  name={`bidItems.${index}.containerType`}
                  control={control}
                  label="컨테이너 종류"
                  options={ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.map((v) => {
                    return v.value;
                  })}
                  required={true}
                />
              </Grid>

              <Grid item xs={3}>
                <NumericFormatWithReactHookForm
                  name={`bidItems.${index}.quantity`}
                  control={control}
                  label="수량"
                  required={true}
                />
              </Grid>

              <Grid item xs={3}>
                <SwitchWithReactHookForm
                  label="위험물"
                  control={control}
                  name={`bidItems.${index}.isDangerous`}
                  defaultValue={false}
                />
              </Grid>

              <Grid item>
                <Button
                  disabled={fields.length === 1}
                  color="error"
                  onClick={() => handleRemoveForm(index)}
                >
                  삭제
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid item>
        <Button
          variant="contained"
          color="success"
          onClick={() =>
            append({
              name: "",
              containerType: "20DRY",
              isDangerous: false,
              quantity: 0,
              mode: "total",
            })
          }
        >
          추가
        </Button>
      </Grid>

      <Grid item>
        <Button variant="contained" type="submit">
          수정
        </Button>
      </Grid>
    </Grid>
  );
}

export default FCLItemForm;
