import { useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ExporterInfo } from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { checkCanEditBidItem } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  getConsolidationItemList,
  getPackageLabel,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import ItemUpdateModal from "../components/ItemUpdateModal";

import ChangeExporterInfoModal from "./ChangeExporterInfoModal";

function ConsolidationCargoInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const consolidationItemList = getConsolidationItemList({
    shipmentItemList: bidDetail.bidItems,
  });

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);
  const [consolidationExporterGroupId, setConsolidationExporterGroupId] =
    useState<number | undefined>(undefined);
  const [showsChangeExporterInfoModal, setShowsChangeExporterInfoModal] =
    useState(false);
  const [exporterInfo, setExporterInfo] = useState<ExporterInfo>({
    companyName: "",
    personName: "",
    personPhone: "",
    personEmail: "",
    exporterGroup: 0,
  });

  const handleChangeExporterInfoModalOpen = (
    exporterInfo: ExporterInfo,
    exporterGroup: number
  ) => {
    return () => {
      setExporterInfo({
        companyName: exporterInfo.companyName,
        personEmail: exporterInfo.personEmail,
        personName: exporterInfo.personName,
        personPhone: exporterInfo.personPhone,
        exporterGroup: exporterGroup,
      });

      setShowsChangeExporterInfoModal(true);
    };
  };

  const handleChangeExporterInfoModalClose = () => {
    setExporterInfo({
      companyName: "",
      personEmail: "",
      personName: "",
      personPhone: "",
      exporterGroup: 0,
    });

    setShowsChangeExporterInfoModal(false);
  };

  const handleItemUpdateModalOpen = (consolidationExporterGroupId: number) => {
    setConsolidationExporterGroupId(consolidationExporterGroupId);
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setConsolidationExporterGroupId(undefined);
    setShowsItemUpdateModal(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {consolidationItemList.map((consolidationItem, index) => {
        return (
          <Grid item key={consolidationItem.exporterGroup}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    sx={{ backgroundColor: `${grey[100]}`, padding: 1 }}
                    xs={12}
                    spacing={1}
                  >
                    {/* TODO: 콘솔 EXW일 때 수출자 정보에 출발지가 추가되어야 함 */}
                    <Grid item container alignItems="center" xs={1.2}>
                      <Grid item>
                        <Typography
                          sx={{ color: `${blue[300]}` }}
                          variant="body1"
                          component="div"
                        >
                          {`수출자 ${index + 1} 정보`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={3}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          COMPANY NAME :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.companyName}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={2}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          NAME :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personName}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={3}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          EMAIL :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personEmail}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={2}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          TEL :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personPhone}
                        </Typography>
                      </Grid>
                    </Grid>

                    {APP_NAME === "shipda-admin" && (
                      <Grid item container alignItems="center" xs={0.8}>
                        <Grid item>
                          <Button
                            onClick={handleChangeExporterInfoModalOpen(
                              consolidationItem.exporterInfo,
                              consolidationItem.exporterGroup
                            )}
                          >
                            수정
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {consolidationItem.itemList.map((consolidationItem) => {
                    return (
                      <Grid
                        key={consolidationItem.id}
                        item
                        container
                        xs={12}
                        spacing={1}
                      >
                        {bidDetail.incoterms === "EXW" && (
                          <Grid item container xs={12} spacing={1}>
                            <Grid
                              item
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography variant="body2" component="div">
                                  출발지 주소:
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography variant="body1" component="div">
                                  {consolidationItem.address}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item container xs={12} spacing={1}>
                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={3}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                ITEM :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.name}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={2}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                W :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.horizontal}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={2}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                L :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.vertical}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={1}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                H :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.height}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={2}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                UNIT :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.volumeUnit}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={2}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                WEIGHT :
                              </Typography>
                            </Grid>

                            <Grid item>
                              {/*TODO: 특송 시 무게 중량에 대한 기준을 정하면 무게 단위 추가 */}
                              <Typography variant="body1" component="div">
                                {consolidationItem.weight}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item container xs={12}>
                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={3}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                NUMBER OF BOXES :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.quantity}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={2}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                CBM :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {consolidationItem.cbm}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            container
                            alignItems="center"
                            xs={3}
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" component="div">
                                PACKING :
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography variant="body1" component="div">
                                {getPackageLabel(consolidationItem.packingType)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}

                  {APP_NAME === "shipda-admin" && (
                    <Grid item xs={3}>
                      <Grid item>
                        <Button
                          disabled={checkCanEditBidItem(
                            bidDetail.projectStatus,
                            currentAdminAuthInfo?.authority
                          )}
                          onClick={() =>
                            handleItemUpdateModalOpen(
                              consolidationItem.exporterGroup
                            )
                          }
                        >
                          수정
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {showsItemUpdateModal && consolidationExporterGroupId && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          showsItemUpdateModal={showsItemUpdateModal}
          onItemUpdateModalClose={handleItemUpdateModalClose}
          consolidationExporterGroupId={consolidationExporterGroupId}
        />
      )}

      {showsChangeExporterInfoModal && (
        <ChangeExporterInfoModal
          handleChangeExporterInfoModalClose={
            handleChangeExporterInfoModalClose
          }
          showsChangeExporterInfoModal={showsChangeExporterInfoModal}
          exporterInfo={exporterInfo}
          bidId={bidDetail.id}
        />
      )}
    </Grid>
  );
}

export default ConsolidationCargoInfo;
