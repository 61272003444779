import { useCallback } from "react";
import { Control, UseFieldArrayRemove, UseFormSetValue } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Button, Grid, TextField } from "@mui/material";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { ITEM_UNIT_MEASUREMENT_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import {
  getAmountByItemUnitMeasurement,
  getLCLRtonAmount,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";

import NumericFormatWithReactHookForm from "../../../../../../../components/NumericFormatWithReactHookForm";

function FormList({
  control,
  formListIndex,
  formData,
  remove,
  setValue,
  getNewFormDataItem,
  withdrawalItemList,
  exchangeRateList,
  companyType,
  trelloDetailData,
}: {
  control: Control<ApplyBidFormData, Record<string, unknown>>;
  formListIndex: number;
  formData: WithdrawalFormDetail;
  remove: UseFieldArrayRemove;
  setValue: UseFormSetValue<ApplyBidFormData>;
  getNewFormDataItem: (formData: WithdrawalFormDetail) => WithdrawalFormDetail;
  withdrawalItemList: string[];
  exchangeRateList: ExchangeRate[];
  companyType: PartnerBusinessArea;
  trelloDetailData: TrelloBidDetail;
}) {
  const handleRemoveForm = () => {
    remove(formListIndex);
  };

  const handleFormChange = useCallback(() => {
    setValue(`withdrawalData.${formListIndex}`, getNewFormDataItem(formData));
  }, [formData, formListIndex, getNewFormDataItem, setValue]);

  const handleFinalPriceChange = () => {
    const finalPrice = formData.totalPrice + formData.vatPrice;

    const newFormData = {
      ...formData,
      finalPrice: finalPrice,
    };

    setValue(`withdrawalData.${formListIndex}`, newFormData);
  };

  const handleItemUnitMeasurementChange = useCallback(() => {
    const isNotConsolidationLCLRton =
      trelloDetailData.serviceType !== "consolidation" &&
      trelloDetailData.freightType === "LCL" &&
      formData.itemUnitMeasurement === "R.TON";

    const newAmount = isNotConsolidationLCLRton
      ? // 콘솔이 아닌 LCL의뢰 R.TON인 경우 수량은 유니패스 값으로 해야한다.
        getLCLRtonAmount({
          unipassTotalCBM: trelloDetailData.management.invoiceCbm,
          shipmentSupply: trelloDetailData.supply,
        })
      : getAmountByItemUnitMeasurement({
          shipmentSupply: trelloDetailData.supply,
          itemUnitMeasurement: formData.itemUnitMeasurement,
          shipmentItemList: trelloDetailData.bidItems,
          serviceType: trelloDetailData.serviceType,
          wareHouseCbm: trelloDetailData?.wareHouse?.cbm,
          totalWeight: trelloDetailData.totalWeight,
          totalCBM: trelloDetailData.totalCBM,
          unipassTotalCBM: trelloDetailData.management.invoiceCbm,
          freightType: trelloDetailData.freightType,
        });

    const newFormData = getNewFormDataItem({ ...formData, amount: newAmount });

    setValue(
      `withdrawalData.${formListIndex}`,
      getNewFormDataItem(newFormData)
    );
  }, [
    formData,
    formListIndex,
    getNewFormDataItem,
    setValue,
    trelloDetailData.bidItems,
    trelloDetailData.freightType,
    trelloDetailData.management.invoiceCbm,
    trelloDetailData.serviceType,
    trelloDetailData.supply,
    trelloDetailData.totalCBM,
    trelloDetailData.totalWeight,
    trelloDetailData?.wareHouse?.cbm,
  ]);

  return (
    <Grid
      item
      container
      columns={24}
      spacing={1}
      sx={{ marginTop: "8px" }}
      key={formListIndex}
    >
      <Grid item xs={5}>
        <AutoCompleteWithReactHookForm
          name={`withdrawalData.${formListIndex}.name`}
          control={control}
          defaultValue={formData.name}
          options={withdrawalItemList}
        />
      </Grid>

      <Grid item xs={2}>
        <AutoCompleteWithReactHookForm
          name={`withdrawalData.${formListIndex}.currency`}
          control={control}
          options={exchangeRateList.map((v) => {
            return v.currency;
          })}
          handleEffectOnChange={handleFormChange}
        />
      </Grid>

      <Grid item xs={2}>
        <NumericFormatWithReactHookForm
          name={`withdrawalData.${formListIndex}.unitPrice`}
          control={control}
          inputProps={{ style: { textAlign: "right" } }}
          defaultValue={formData.unitPrice}
          handleEffectOnChange={handleFormChange}
          thousandSeparator={true}
          decimalScale={2}
        />
      </Grid>

      <Grid item xs={3.5}>
        <AutoCompleteWithReactHookForm
          name={`withdrawalData.${formListIndex}.itemUnitMeasurement`}
          control={control}
          options={ITEM_UNIT_MEASUREMENT_OPTION_LIST.map((v) => {
            return v.value;
          })}
          handleEffectOnChange={handleItemUnitMeasurementChange}
        />
      </Grid>

      <Grid item xs={1.5}>
        <NumericFormatWithReactHookForm
          name={`withdrawalData.${formListIndex}.amount`}
          control={control}
          inputProps={{ style: { textAlign: "right" } }}
          defaultValue={formData.amount}
          handleEffectOnChange={handleFormChange}
          thousandSeparator={true}
          decimalScale={3}
        />
      </Grid>

      {companyType !== "foreign" && (
        <Grid item xs={2.5}>
          <NumericFormatWithReactHookForm
            name={`withdrawalData.${formListIndex}.totalPrice`}
            control={control}
            inputProps={{ style: { textAlign: "right" } }}
            defaultValue={formData.totalPrice}
            thousandSeparator={true}
            handleEffectOnChange={handleFinalPriceChange}
          />
        </Grid>
      )}

      {companyType !== "foreign" && (
        <Grid item xs={2}>
          <NumericFormatWithReactHookForm
            disabled={
              formData.name === "WFG" ||
              formData.name === "PFS" ||
              formData.name === "보험료"
            }
            name={`withdrawalData.${formListIndex}.vatPrice`}
            control={control}
            inputProps={{ style: { textAlign: "right" } }}
            defaultValue={formData.vatPrice}
            thousandSeparator={true}
            handleEffectOnChange={handleFinalPriceChange}
          />
        </Grid>
      )}

      <Grid item xs={2}>
        <NumericFormat
          value={(() => {
            if (formData.currency === "KRW" || !formData.currency) {
              // numericFormat value에서 null과 undefined을 넣을 시 인식하지 못함
              return "";
            }

            return formData.unitPrice * (formData.amount ?? 1);
          })()}
          customInput={TextField}
          decimalScale={2}
          variant={"standard"}
          inputProps={{ style: { textAlign: "right" } }}
          thousandSeparator={true}
        />
      </Grid>

      <Grid item xs={2.5}>
        <NumericFormatWithReactHookForm
          name={`withdrawalData.${formListIndex}.finalPrice`}
          control={control}
          inputProps={{ style: { textAlign: "right" } }}
          defaultValue={formData.finalPrice}
          thousandSeparator={true}
        />
      </Grid>

      <Grid item xs={1}>
        <Button color="error" onClick={handleRemoveForm}>
          삭제
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormList;
