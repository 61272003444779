import { Box, Divider, Typography } from "@mui/material";
import { Descriptions } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  getSupplyValueInUnit,
  getTitleAmongItemListName,
} from "@sellernote/_shared/src/utils/forwarding/bid";

import FCLItemList from "./FCLItemList";
import LCLAndAIRItemList from "./LCLAndAIRItemList";

export default function GeneralCargoInfo({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) {
  return (
    <Box>
      <Descriptions
        title={<Box sx={{ fontWeight: "bold", fontSize: 20 }}>화물정보</Box>}
        colon={false}
      >
        <Descriptions.Item label="품명 :">
          {getTitleAmongItemListName(bidDetail.bidItems)}
        </Descriptions.Item>

        <Descriptions.Item label="운송수단 :">
          {bidDetail.freightType}
        </Descriptions.Item>

        <Descriptions.Item label="총 물동량 :">
          {getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply)}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Typography
        sx={{
          fontSize: "20px",
          marginTop: "20px",
          fontWeight: "bold",
        }}
      >
        물품 상세
      </Typography>

      {bidDetail.freightType === "FCL" && <FCLItemList bidDetail={bidDetail} />}

      {(bidDetail.freightType === "LCL" || bidDetail.freightType === "AIR") && (
        <LCLAndAIRItemList bidDetail={bidDetail} />
      )}
    </Box>
  );
}
