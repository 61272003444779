import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import ZoneAddressTextFiled from "@sellernote/_shared-for-forwarding-admin/src/containers/ZoneAddressTextField";

function ConsolidationEndAddress({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const queryClient = useQueryClient();

  const [updateData, setUpdateData] = useState({});
  const [endAddressDetail, setEndAddressDetail] = useState(
    bidDetail.endAddressDetail
      ? bidDetail.endAddressDetail
      : bidDetail.endAddress
  );
  const [canEdit, setCanEdit] = useState(false);

  const { mutate: changeConsolidationInfo } =
    ADMIN_BID_QUERY.useChangeAdminBidConsolidationInfo(bidDetail.id);

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
  } = useUploadResponseSnackBar();

  const endAddress = useMemo(() => {
    if (bidDetail.endAddressDetail) {
      return bidDetail.endAddress;
    }
    return bidDetail.zone?.name || "";
  }, [bidDetail.endAddress, bidDetail.endAddressDetail, bidDetail.zone?.name]);

  const handleEndAddressChange = () => {
    changeConsolidationInfo(
      {
        ...updateData,
        endAddressDetail,
        isProductInfoChanged: true,
      },
      {
        onSuccess: () => {
          setCanEdit(false);
          setShowsSuccessSnackBar(true);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  };

  const handleEditModeClose = () => {
    setCanEdit(false);
    setEndAddressDetail(
      bidDetail.endAddressDetail
        ? bidDetail.endAddressDetail
        : bidDetail.endAddress
    );
    setUpdateData({});
  };

  return (
    <Grid container sx={{ marginTop: "32px" }}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item xs={10}>
          <Typography variant="h6" component="div">
            도착지 정보
          </Typography>
        </Grid>

        {!canEdit && APP_NAME === "shipda-admin" && (
          <Grid item>
            <Button onClick={() => setCanEdit(true)}>수정</Button>
          </Grid>
        )}

        {canEdit && (
          <Grid item container xs={2}>
            <Grid item>
              <Button onClick={handleEndAddressChange}>확인</Button>
            </Grid>

            <Grid item>
              <Button color="error" onClick={handleEditModeClose}>
                취소
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              도착지 :
            </Typography>
          </Grid>

          <Grid item>
            {canEdit ? (
              <ZoneAddressTextFiled
                zoneName={endAddress}
                pageType="bid"
                setBidUpdateState={setUpdateData}
                updateData={updateData}
                isImport={bidDetail.isImport}
              />
            ) : (
              <Typography variant="body1" component="div">
                {endAddress}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              상세주소 :
            </Typography>
          </Grid>

          <Grid item>
            {canEdit ? (
              <TextField
                type="text"
                label="상세 주소"
                fullWidth
                value={endAddressDetail}
                onChange={(e) => setEndAddressDetail(e.target.value)}
                autoComplete="off"
              />
            ) : (
              <Typography variant="body1" component="div">
                {endAddressDetail}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {UploadResponseSnackBar}
    </Grid>
  );
}
export default ConsolidationEndAddress;
