import { useCallback, useMemo } from "react";

import { useTranslation } from "@sellernote/_shared-for-shipda/src/utils/i18n";

import { Coordinate, FreightType } from "../../types/common/common";
import { BidPortMap } from "../../types/forwarding/bid";
import { isEmptyObjectOrArray } from "../../utils/common/etc";
import { findNearestIndex } from "../../utils/common/googleMap";

import {
  InfoWindow,
  SvgTypeIconInfo,
} from "../../components/googleMap/GoogleMapWithCluster";

import { GET_BID_TRACKING_SHIP_RES } from "../../api-interfaces/shipda-api/bid";
import { APP_CURRENT_LANGUAGE, APP_NAME } from "../../constants";

export default function useShipmentMapMarkerInfoList({
  trackingShipResult,
  freightType,
}: {
  trackingShipResult: GET_BID_TRACKING_SHIP_RES | undefined;
  freightType: FreightType | undefined;
}) {
  const { t } = useTranslation(["bid"]);

  function getBidMapPortCoordinate(port?: BidPortMap): Coordinate | undefined {
    if (!port || !port.lat || !port.lng) {
      return;
    }

    return {
      lat: port.lat,
      lng: port.lng,
      name:
        APP_CURRENT_LANGUAGE === "ko" ? port.name || port.nameEN : port.nameEN,
    };
  }

  /** 현재 운송 진행중 여부 */
  const hasMoving =
    trackingShipResult &&
    trackingShipResult.pin &&
    Boolean(trackingShipResult.pin.lat) &&
    Boolean(trackingShipResult.pin.lng);

  /** 맵의 센터 포지션 */
  const centerPosition = useMemo(() => {
    if (hasMoving) {
      return {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      };
    }

    return { lat: 35, lng: 125 };
  }, [hasMoving, trackingShipResult]);

  const polylineData = useMemo(() => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult.routes)) return;

    return trackingShipResult.routes.flatMap((routeArray) =>
      routeArray.map((port) => ({ lat: port.lat, lng: port.lng }))
    );
  }, [trackingShipResult]);

  const splitIndex = useMemo(() => {
    if (!trackingShipResult) return;

    if (!polylineData) return;

    return findNearestIndex({
      pinLat: trackingShipResult?.pin.lat,
      pinLng: trackingShipResult?.pin.lng,
      routes: polylineData,
    });
  }, [polylineData, trackingShipResult]);

  /** 출발지 위도, 경도를 구하는 함수 */
  const getDeparture = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.startPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.startPort]);

  /** 도착지 위도, 경도를 구하는 함수 */
  const getDestination = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.endPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.endPort]);

  /** 경유지를 구하는 함수 */
  const getStopovers = useCallback((): Coordinate[] | undefined => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult)) return;

    if (!trackingShipResult.pin) return;

    // 항적(폴리라인)이 있다면 마커의 위치를 실선 폴리라인의 마지막 좌표로 설정
    if (polylineData && splitIndex) {
      return [polylineData[splitIndex]];
    }

    return [
      {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      },
    ];
  }, [polylineData, splitIndex, trackingShipResult]);

  /** 현재 이동중인 화물 위도, 경도 */
  const shipPosition = useMemo((): Coordinate | undefined => {
    const stopovers = getStopovers();
    if (!stopovers?.length) {
      return getDeparture();
    }

    return stopovers[0];
  }, [getDeparture, getStopovers]);

  const getMarkerInfoListTitleImg = useCallback(() => {
    const isShipda = APP_NAME === "shipda-kr" || APP_NAME === "shipda-sg";

    const planeIconUrl = isShipda
      ? "/assets/images/mypage/bid/marker-info-icon-plane.svg"
      : "/images/trello/marker-info-icon-plane.svg";

    const shipIconUrl = isShipda
      ? "/assets/images/mypage/bid/marker-info-icon-ship.svg"
      : "/images/trello/marker-info-icon-ship.svg";

    if (freightType === "AIR") {
      return `<img src=${planeIconUrl} alt="marker-info-plane">`;
    }
    return `<img src=${shipIconUrl} alt="marker-info-ship"/>`;
  }, [freightType]);

  const markerInfoList = useMemo(() => {
    if (!trackingShipResult) return;

    const parser = new DOMParser();

    const departure = getDeparture();
    const destination = getDestination();

    const departureContent = `<div class="departure">
      <div class="title">
       ${getMarkerInfoListTitleImg()}

        <div> ${t("bid:운송관리_공통_TRACKING_MAP_출발지")} </div>
      </div>
        
      <div class="port">${departure?.name}</div>
      </div>`;

    const destinationContent = `<div class="departure">
      <div class="title">
       ${getMarkerInfoListTitleImg()}

        <div> ${t("bid:운송관리_공통_TRACKING_MAP_도착지")} </div>
      </div>
        
      <div class="port">${destination?.name}</div>
      </div>`;

    const shipSvg = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="white" fill-opacity="0.4"/>
    <circle cx="15" cy="15" r="9" transform="rotate(-90 15 15)" fill="#D65481" stroke="#D65481" stroke-width="2"/>
    <path d="M15 22.5C16.9891 22.5 18.8968 21.7098 20.3033 20.3033C21.7098 18.8968 22.5 16.9891 22.5 15C22.5 13.0109 21.7098 11.1032 20.3033 9.6967C18.8968 8.29018 16.9891 7.5 15 7.5C13.0109 7.5 11.1032 8.29018 9.6967 9.6967C8.29018 11.1032 7.5 13.0109 7.5 15C7.5 16.9891 8.29018 18.8968 9.6967 20.3033C11.1032 21.7098 13.0109 22.5 15 22.5ZM18.5449 15.4395C18.8203 15.7148 18.8203 16.1602 18.5449 16.4326C18.2695 16.7051 17.8242 16.708 17.5518 16.4326L15.0029 13.8838L12.4541 16.4326C12.1787 16.708 11.7334 16.708 11.4609 16.4326C11.1885 16.1572 11.1855 15.7119 11.4609 15.4395L14.502 12.3926C14.7773 12.1172 15.2227 12.1172 15.4951 12.3926L18.5449 15.4395Z" fill="white"/>
    </svg>`;

    const portSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="3.5" fill="#EFBED2" stroke="#D65481"/>
    </svg>`;

    const parsedShipSvg = parser.parseFromString(
      shipSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDepartureSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    const departurePortSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    parsedShipSvg.style.transform = `translate(15%, 35%) rotate(${
      // heading 값이 null일 때가 있음
      trackingShipResult.pin.heading || 0
    }deg)`;
    parsedShipSvg.style.transformOrigin = "center";

    parsedDepartureSvg.style.position = "absolute";
    parsedDepartureSvg.style.transform = "translate(-50%, -50%)";

    departurePortSvg.style.position = "absolute";
    departurePortSvg.style.transform = "translate(-50%, -50%)";

    return [
      ...(departure
        ? [
            {
              ...departure,
              iconInfo: {
                type: "svg",
                svg: parsedDepartureSvg,
              } as SvgTypeIconInfo,
              infoWindowData: {
                content: departureContent,
                type: "visible",
              } as InfoWindow,
              title: "departure",
            },
          ]
        : []),

      ...(shipPosition?.lat && shipPosition?.lng
        ? [
            {
              ...shipPosition,
              iconInfo: {
                type: "svg",
                svg: parsedShipSvg,
              } as SvgTypeIconInfo,
              title: "shipPosition",
            },
          ]
        : []),

      ...(destination
        ? [
            {
              ...destination,
              iconInfo: {
                type: "svg",
                svg: departurePortSvg,
              } as SvgTypeIconInfo,
              infoWindowData: {
                content: destinationContent,
                type: "visible",
              } as InfoWindow,
              title: "destination",
            },
          ]
        : []),
    ];
  }, [
    getDeparture,
    getDestination,
    getMarkerInfoListTitleImg,
    shipPosition,
    t,
    trackingShipResult,
  ]);

  return {
    centerPosition,
    markerInfoList,
  };
}
