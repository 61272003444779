import { FormProvider, useForm } from "react-hook-form";

import {
  AdminBidDetail,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import QuotationResetModal from "../../../components/QuotationResetModal";

import FCLItemForm from "./FCLItemForm";
import LCLAndAIRItemForm from "./LCLAndAIRItemForm";
import useItemUpdateRequest from "./useItemUpdateRequest";

function ItemUpdateModal({
  bidDetail,
  showsItemUpdateModal,
  onItemUpdateModalClose,
  consolidationExporterGroupId,
}: {
  bidDetail: AdminBidDetail;
  showsItemUpdateModal: boolean;
  onItemUpdateModalClose: () => void;
  consolidationExporterGroupId?: number;
}) {
  const cargoItemList = consolidationExporterGroupId
    ? /** 특송은 수정 시 수출자(판매자)별 개별 수정함 */
      bidDetail.bidItems.filter((item) => {
        return item.exporterGroup === consolidationExporterGroupId;
      })
    : [...bidDetail.bidItems];

  /** form 입력에서는 값들이 옵셔널일 수 있음(수정 시에는 수정한 항목만 필요, 새로운 화물 추가시는 특정 항목만 사용해야함) */
  const methods = useForm<{ bidItems: Partial<ShipmentItem>[] }>({
    defaultValues: {
      bidItems: cargoItemList,
    },
  });

  const {
    watch,
    formState: { dirtyFields },
  } = methods;

  const isTotalVolumeAndWeight = (() => {
    if (bidDetail.serviceType === "consolidation") {
      /** 수출자로 필터된 리스트의 첫번째 mode가 total이라면 총 포장별 부피 입력 화물 */
      return (
        bidDetail.bidItems.filter((item) => {
          return item.exporterGroup === consolidationExporterGroupId;
        })[0].mode === "total"
      );
    }
    return (
      bidDetail.freightType !== "FCL" && bidDetail.bidItems[0].mode === "total"
    );
  })();

  const {
    handleRecalculationCheckAndUpdate,
    handleShipmentItemWithoutRecalculationUpdate,
    UploadResponseSnackBar,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
  } = useItemUpdateRequest({
    shipmentDetail: bidDetail,
    consolidationExporterGroupId,
    formData: watch("bidItems"),
    dirtyFields,
  });

  return (
    <Modal
      isOpened={showsItemUpdateModal}
      handleClose={onItemUpdateModalClose}
      modalBody={
        <FormProvider {...methods}>
          {bidDetail.freightType === "FCL" ? (
            <FCLItemForm
              onShipmentItemWithoutRecalculationUpdate={
                handleRecalculationCheckAndUpdate
              }
            />
          ) : (
            <LCLAndAIRItemForm
              isTotalVolumeAndWeight={isTotalVolumeAndWeight}
              shipmentItemList={cargoItemList}
              onShipmentItemWithoutRecalculationUpdate={
                handleRecalculationCheckAndUpdate
              }
            />
          )}

          {showsQuotationResetModal && (
            <QuotationResetModal
              showsQuotationResetModal={showsQuotationResetModal}
              onQuotationResetModalClose={() =>
                setShowsQuotationResetModal(false)
              }
              onShipmentWithoutRecalculationUpdate={
                handleShipmentItemWithoutRecalculationUpdate
              }
            />
          )}

          {UploadResponseSnackBar}
        </FormProvider>
      }
    />
  );
}

export default ItemUpdateModal;
