import { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { WithdrawalFormDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

function PurchasePriceSummary({
  companyType,
  withdrawalData,
  currency,
}: {
  companyType: PartnerBusinessArea;
  withdrawalData: WithdrawalFormDetail[];
  currency: Currency;
}) {
  const getTotalPrice = useCallback(
    (type: "totalPrice" | "vatPrice" | "finalPrice") => {
      return withdrawalData?.reduce((acc, cur) => {
        acc += cur[type];

        return acc;
      }, 0);
    },
    [withdrawalData]
  );

  /** 합계(외화) 값을 리턴 */
  const getTotalForeignCurrency = () => {
    return withdrawalData?.reduce((acc, cur) => {
      if (cur.currency && cur.currency !== "KRW") {
        acc += cur.amount * cur.unitPrice;
      } else {
        acc += 0;
      }

      return acc;
    }, 0);
  };

  const getForeignCurrencyTotalPrice = useCallback(() => {
    return withdrawalData?.reduce((acc, cur) => {
      if (cur.currency === currency) {
        acc += cur.amount * cur.unitPrice;
      }

      return acc;
    }, 0);
  }, [currency, withdrawalData]);

  return (
    <Grid item container>
      <Grid
        item
        container
        columns={24}
        alignItems="center"
        sx={{ height: "32px", marginTop: 2 }}
      >
        <Grid item xs={13}></Grid>

        <Grid item xs={1}>
          <Typography variant="body1" component="div" textAlign={"right"}>
            합계:
          </Typography>
        </Grid>

        {companyType !== "foreign" && (
          <Grid item xs={2.5}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(getTotalPrice("totalPrice"))}
            </Typography>
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(getTotalPrice("vatPrice"))}
            </Typography>
          </Grid>
        )}

        <Grid item xs={2}>
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(getTotalForeignCurrency())}
          </Typography>
        </Grid>

        <Grid item xs={2.5}>
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(getTotalPrice("finalPrice"))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PurchasePriceSummary;
