import { useMemo } from "react";
import { Box } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  PartnerTrelloList,
  TrelloIssueFilter,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIfCustomsClearanceHasStarted,
  checkIsBlNumberHasRegistered,
  checkIsCustomsPartnerHasRegistered,
  checkIsInlandFarePaymentRequest,
  checkIsInlandPartnerHasRegistered,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import {
  getTrelloCardCommonBody,
  getTrelloCardCustomsChip,
  getTrelloCardTitle,
} from "../utils";
import {
  filterByTrelloCardIssue,
  sortByShipmentScheduleDateDesc,
} from "./utils";

import TrelloCardCheckBox from "../components/TrelloCardCheckBox";
import { CardListData } from "../components/TrelloCardList";

export default function usePartnerCardListData({
  trelloListData,
  handleTrelloCardClick,
  sessionStorageTrelloIssueFilter,
}: {
  trelloListData: PartnerTrelloList[];
  handleTrelloCardClick: (cardData: PartnerTrelloList) => void;
  sessionStorageTrelloIssueFilter: TrelloIssueFilter | null;
}) {
  const partnerCardListData: CardListData[] = useMemo(() => {
    return [
      {
        lineKey: "1",
        lineTitle: "수출자 컨택 전",
        cards: trelloListData
          .filter(
            (v: PartnerTrelloList) => v.projectStatus === "beforeContactPartner"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ETD"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "2",
        lineTitle: "수출자 컨택 중",
        cards: trelloListData
          .filter(
            (v: PartnerTrelloList) => v.projectStatus === "contactingPartner"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ETD"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  {APP_NAME === "shipda-admin" && (
                    <TrelloCardCheckBox label="스케줄 안내" />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => {
                handleTrelloCardClick(v);
              },
            };
          }),
      },
      {
        lineKey: "3",
        lineTitle: "출항 준비 중",
        cards: trelloListData
          .filter(
            (v: PartnerTrelloList) =>
              v.projectStatus === "scheduling" ||
              v.projectStatus === "containerCarryOut" ||
              v.projectStatus === "containerPickup" ||
              v.projectStatus === "gateIn" ||
              v.projectStatus === "loaded"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ETD"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              projectStatus: v.projectStatus,

              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                  {APP_NAME === "shipda-admin" && (
                    <>
                      <TrelloCardCheckBox
                        label="BL(AWB) 입력"
                        checkedValue={checkIsBlNumberHasRegistered(v)}
                      />

                      <TrelloCardCheckBox
                        label="BL(AWB) 업로드"
                        checkedValue={v.attachmentFlag}
                      />

                      {v.freightType === "FCL" && (
                        <TrelloCardCheckBox
                          label="공 컨테이너 반출"
                          checkedValue={
                            v.projectStatus === "containerCarryOut" ||
                            v.projectStatus === "containerPickup" ||
                            v.projectStatus === "gateIn" ||
                            v.projectStatus === "loaded" ||
                            v.projectStatus === "moving"
                          }
                        />
                      )}

                      {v.freightType === "FCL" && (
                        <TrelloCardCheckBox
                          label="현지 컨테이너 픽업완료"
                          checkedValue={
                            v.projectStatus === "containerPickup" ||
                            v.projectStatus === "gateIn" ||
                            v.projectStatus === "loaded" ||
                            v.projectStatus === "moving"
                          }
                        />
                      )}

                      {/* TODO: 운송 리뉴얼에서 빠진 기능 추후에 추가 예정 */}
                      {v.freightType !== "FCL" && (
                        <TrelloCardCheckBox label="현지 CFS 반입완료" />
                      )}

                      <TrelloCardCheckBox
                        label="현지 터미널 반입완료"
                        checkedValue={
                          v.projectStatus === "gateIn" ||
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />

                      <TrelloCardCheckBox
                        label="선적 완료"
                        checkedValue={
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />

                      <TrelloCardCheckBox
                        label="출항 완료"
                        checkedValue={v.projectStatus === "moving"}
                      />
                    </>
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "4",
        lineTitle: "출항 완료",
        cards: trelloListData
          .filter((v: PartnerTrelloList) => v.projectStatus === "moving")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ETA"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isCompletedCustoms:
                  APP_NAME === "partner-admin" &&
                  checkIfCustomsClearanceHasStarted(v),
              },
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "5",
        lineTitle: "입항완료 및 통관 준비",
        cards: trelloListData
          .filter(
            (v: PartnerTrelloList) =>
              v.projectStatus === "portEntryAndPrepareCustoms"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ATA"))
          .map((v: PartnerTrelloList) => {
            const customsAccountPayable = v.accountPayables.filter(
              ({ domain }) => domain === "customs"
            )[0];

            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isCompletedCustoms: checkIfCustomsClearanceHasStarted(v),
              },
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  <TrelloCardCheckBox
                    label="통관 필수서류 구비완료"
                    checkedValue={v.attachmentFlag}
                  />

                  <TrelloCardCheckBox
                    label="관세사 지정하기"
                    checkedValue={checkIsCustomsPartnerHasRegistered(v)}
                  />

                  <TrelloCardCheckBox
                    label="통관 서류 및 거래명세서 발송(관세사)"
                    checkedValue={v.management.detailStatus === "sendedCustoms"}
                  />

                  {/** SG쉽다 의뢰의 경우 유니패스를 사용하지 않아 거래명세서 발송으로 대체 */}
                  {v.region === "KR" ? (
                    <TrelloCardCheckBox label="수입결재통보" />
                  ) : (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "6",
        lineTitle: "정산 요청",
        cards: trelloListData
          .filter((v: PartnerTrelloList) => v.projectStatus === "payment")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ATA"))
          .map((v: PartnerTrelloList) => {
            const customsAccountPayable = v.accountPayables.filter(
              ({ domain }) => domain === "customs"
            )[0];

            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  {customsAccountPayable?.customsReviewRequestedFlag && (
                    <Box
                      sx={{
                        background: "#50C878",
                        width: "100px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      <p className="review-request">서류검토 요청</p>
                    </Box>
                  )}

                  {/** SG쉽다 의뢰에서 거래명세서 발송은 입항완료 및 통관 준비 단계에서 필요한 조건 */}
                  {v.region === "KR" && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}

                  <TrelloCardCheckBox label="수입신고수리" />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "7",
        lineTitle: "통관 완료",
        cards: trelloListData
          .filter(
            (v: PartnerTrelloList) => v.projectStatus === "completeCustoms"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ATA"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isCompletedCustoms:
                  APP_NAME === "shipda-admin" &&
                  checkIsInlandFarePaymentRequest(v),
              },
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {v.endAddress && (
                    <TrelloCardCheckBox
                      label="기사 정보 입력"
                      checkedValue={checkIsInlandPartnerHasRegistered(v)}
                    />
                  )}

                  {v.containCustoms && (
                    <TrelloCardCheckBox
                      label="수입신고필증/계산서 업로드"
                      checkedValue={v.attachmentFlag}
                    />
                  )}

                  <TrelloCardCheckBox
                    label="반출 신고"
                    checkedValue={v.management.confirmFlag}
                  />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "8",
        lineTitle: "내륙 운송 중",
        cards: trelloListData
          .filter((v: PartnerTrelloList) => v.projectStatus === "delivering")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ATA"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "9",
        lineTitle: "종료",
        cards: trelloListData
          .filter((v: PartnerTrelloList) => v.projectStatus === "finished")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("ATA"))
          .map((v: PartnerTrelloList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  <TrelloCardCheckBox
                    label="정산 하기"
                    checkedValue={checkIsInlandPartnerHasRegistered(v)}
                  />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
    ];
  }, [handleTrelloCardClick, sessionStorageTrelloIssueFilter, trelloListData]);

  return { partnerCardListData };
}
