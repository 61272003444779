import {
  Country,
  FreightType,
  Port,
  SellernoteAppRegion,
} from "../../types/common/common";
import { BidProjectStatus, BidServiceType } from "../../types/forwarding/bid";
import {
  InvoiceData,
  InvoiceDataFeeItem,
  TradingStatementElem,
  TradingStatementInvoice,
} from "../../types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
  TrelloBidManagement,
  WithdrawalFormDetail,
} from "../../types/forwarding/trello";

import {
  STORAGE_CHARGE_COMMENT,
  TRADING_STATEMENT_COMMON_COMMENT,
} from "../../constants/forwarding/adminTradingStatement";
import {
  FARECON_DOMESTIC_PARTNER_ID,
  FARECON_FOREIGN_PARTNER_ID,
  GLOBELINK_KOREA_PARTNER_ID,
  GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID,
  GLOBELINK_SHANGHAI_FCL_PARTNER_ID,
  GLOBELINKSHA_PARTNER_ID,
} from "./partner";

const getTotalItemPriceOfFeeData = (feeData: TradingStatementElem[]) => {
  const totalPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && !currentValue.isTaxExempt) {
      return accumulator + Number(currentValue.itemPrice);
    }
    return accumulator;
  }, 0);

  return totalPriceOfFeeData;
};

export const getAllTotalItemPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalItemPriceOfFreightFeeData = 0;
  let totalItemPriceOfDomesticFeeData = 0;
  let totalItemPriceOfLocalFeeData = 0;
  let totalItemPriceOfInlandFeeData = 0;
  let totalItemPriceOfOtherFeeData = 0;
  let totalItemPriceOfTaxFeeData = 0;

  if (freight) {
    totalItemPriceOfFreightFeeData = getTotalItemPriceOfFeeData(freight);
  }
  if (domestic) {
    totalItemPriceOfDomesticFeeData = getTotalItemPriceOfFeeData(domestic);
  }
  if (local) {
    totalItemPriceOfLocalFeeData = getTotalItemPriceOfFeeData(local);
  }
  if (inland) {
    totalItemPriceOfInlandFeeData = getTotalItemPriceOfFeeData(inland);
  }
  if (other) {
    totalItemPriceOfOtherFeeData = getTotalItemPriceOfFeeData(other);
  }
  if (tax) {
    totalItemPriceOfTaxFeeData = getTotalItemPriceOfFeeData(tax);
  }

  if (totalItemPriceOfFreightFeeData < 0) {
    totalItemPriceOfFreightFeeData = 0;
  }
  return (
    totalItemPriceOfLocalFeeData +
    totalItemPriceOfDomesticFeeData +
    totalItemPriceOfFreightFeeData +
    totalItemPriceOfInlandFeeData +
    totalItemPriceOfOtherFeeData +
    totalItemPriceOfTaxFeeData
  );
};

const getTotalVatPriceOfFeeData = (feeData: TradingStatementElem[]) => {
  const totalVatPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && currentValue.isVAT) {
      return accumulator + Math.floor(currentValue.itemPrice * 0.1);
    }
    return accumulator;
  }, 0);

  return totalVatPriceOfFeeData;
};

/**
 * 각 운임의 vat 항목 총합 값을 구하는 함수
 */
export const getAllTotalVatPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalVatPriceOfFreightFeeData = 0;
  let totalVatPriceOfDomesticFeeData = 0;
  let totalVatPriceOfLocalFeeData = 0;
  let totalVatPriceOfInlandFeeData = 0;
  let totalVatPriceOfOtherFeeData = 0;
  let totalVatPriceOfTaxFeeData = 0;

  if (freight) {
    totalVatPriceOfFreightFeeData = getTotalVatPriceOfFeeData(freight);
  }
  if (domestic) {
    totalVatPriceOfDomesticFeeData = getTotalVatPriceOfFeeData(domestic);
  }
  if (local) {
    totalVatPriceOfLocalFeeData = getTotalVatPriceOfFeeData(local);
  }
  if (inland) {
    totalVatPriceOfInlandFeeData = getTotalVatPriceOfFeeData(inland);
  }
  if (other) {
    totalVatPriceOfOtherFeeData = getTotalVatPriceOfFeeData(other);
  }
  if (tax) {
    totalVatPriceOfTaxFeeData = getTotalVatPriceOfFeeData(tax);
  }
  return (
    totalVatPriceOfLocalFeeData +
    totalVatPriceOfDomesticFeeData +
    totalVatPriceOfFreightFeeData +
    totalVatPriceOfInlandFeeData +
    totalVatPriceOfOtherFeeData +
    totalVatPriceOfTaxFeeData
  );
};

export const getUnitPriceByCurrencyOfFeeData = (
  exchangeData: ExchangeRate[],
  feeData: TradingStatementElem | WithdrawalFormDetail
) => {
  const currencyIndex = exchangeData.findIndex((v: ExchangeRate) => {
    return (
      v.currency === feeData.currency ||
      (v.currency === "CNH" && feeData.currency === "CNY")
    );
  });

  if (feeData.currency === "KRW") {
    return feeData.unitPrice;
  }
  if (currencyIndex >= 0) {
    const itemPrice = feeData.unitPrice * exchangeData[currencyIndex].rate;
    return itemPrice;
  }
  return feeData.unitPrice;
};

/**
 * RTon을 계산
 * @param {*} volume // cbm과 volume은 같은 값이다 cbm처럼 m단위로 계산된 volume이어야 함
 * @param {*} weight // ton단위여야함
 */
export const calculateRTonForTradingStatement = ({
  shipmentDetailData,
  cbm,
  ton,
  directAirRton,
}: {
  shipmentDetailData: TrelloBidDetail;
  cbm: string | number;
  ton: string | number;
  directAirRton: number | undefined;
}) => {
  /** AIR인 경우 직접 입력한 rton을 리턴 */
  if (directAirRton) return directAirRton;

  const volume = Number(cbm);
  let weight = Number(ton) / 1000;

  if (
    shipmentDetailData.isExpress &&
    shipmentDetailData.freightType !== "AIR"
  ) {
    weight = Number(ton) / 250;
  }
  if (shipmentDetailData.wareHouse) {
    if (
      shipmentDetailData.wareHouse?.nameEN === "YIWU" ||
      shipmentDetailData.wareHouse?.nameEN === "WEIHAI"
    ) {
      weight = Number(ton) / 1000;
    } else {
      weight = Number(ton) / 250;
    }
  }

  const returnValue = volume > weight ? volume : weight;

  if (shipmentDetailData.serviceType !== "oceanTicket") {
    if (returnValue < 1) {
      return 1;
    }
    return returnValue;
  }
  return returnValue;
};

export const checkIfTradingStatementCanBeSendToUser = (
  projectStatus: BidProjectStatus,
  region: SellernoteAppRegion,
  isImport: boolean
) => {
  // 수출에서는 모든 상태에서 발송이 가능함
  if (!isImport) {
    return false;
  }

  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling" ||
    projectStatus === "moving" ||
    /** SG의뢰에서는 입항완료 및 통관 준비에서 고객사 발송이 가능해야함 */
    (projectStatus === "portEntryAndPrepareCustoms" && region === "KR")
  ) {
    return true;
  }
  return false;
};

export const checkIfRefundTradingStatementCanBeSend = (
  projectStatus: BidProjectStatus
) => {
  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

export const checkIfTradingStatementCanBeSendToCustomsBroker = (
  bidData: TrelloBidDetail
) => {
  // 수출에서는 모든 상태에서 발송이 가능함
  if (!bidData.isImport) {
    return false;
  }

  if (bidData.projectStatus === "moving") {
    if (bidData.management?.detailStatus === null) {
      return true;
    }
    return false;
  }
  if (
    bidData.projectStatus === "beforeContactPartner" ||
    bidData.projectStatus === "contactingPartner" ||
    bidData.projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

export const checkIsIssuedInvoices = (
  invoice: TradingStatementInvoice | undefined | null
) => {
  if (invoice) {
    if (invoice.issuedInvoices.length >= 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const findExchangeRate = (
  exchangeRateList: ExchangeRate[] | undefined,
  currency: string
) => {
  const specificExchangeRate = exchangeRateList?.find((v) => {
    return v.currency === currency;
  });

  if (specificExchangeRate) {
    return specificExchangeRate.rate === 0
      ? 1
      : Number(specificExchangeRate.rate);
  } else {
    return 1;
  }
};

export const getTradingStatementArrivalData = (
  invoiceData: InvoiceData | undefined,
  management: TrelloBidManagement
) => {
  return invoiceData?.arrivalDate || management.ATA || "";
};

export const getTradingStatementComment = ({
  invoiceType,
  serviceType,
}: {
  invoiceType: InvoiceType;
  serviceType: BidServiceType;
}) => {
  /** 부킹커미션(데이터는 refund) 명세서에서는 빈스트링을 리턴 */
  if (invoiceType === "refund") return "";

  /** 일반의뢰가 아닌 경우(콘솔, 오션티켓)에만 창고보관료 코멘트 추가 */
  if (serviceType !== "general")
    return STORAGE_CHARGE_COMMENT + "\n" + TRADING_STATEMENT_COMMON_COMMENT;

  return TRADING_STATEMENT_COMMON_COMMENT;
};

export const getTradingStatementPortName = (
  portId: number,
  portData: Port[]
) => {
  return portData.find((v) => v.id === portId)?.nameEN || "-";
};

const getCbmStringIndex = (string: string) => {
  return string.indexOf(" CBM");
};

const getKgsStringIndex = (string: string) => {
  return string.indexOf(" kgs");
};

const getSlashStringIndex = (string: string) => {
  return string.indexOf("/");
};

export const getInvoiceDataCbm = (string: string) => {
  const cbmIndex = getCbmStringIndex(string);
  return string.substring(0, cbmIndex);
};

export const getInvoiceDataWeight = (string: string, freightType: string) => {
  if (freightType === "AIR") {
    const slashIndex = getKgsStringIndex(string);
    return string.substring(0, slashIndex).replace(",", "");
  }
  const slashIndex = getSlashStringIndex(string);
  return string.substring(slashIndex + 2, string.length - 4).replace(",", "");
};

export const getBidDataCbmOrWeight = (
  bidDetailData: TrelloBidDetail,
  type: "cbm" | "weight"
) => {
  if (bidDetailData.freightType === "LCL") {
    if (bidDetailData.serviceType === "consolidation") {
      return type === "cbm"
        ? bidDetailData.totalCBM
        : bidDetailData.totalWeight;
    }

    return bidDetailData.bidItems.reduce((acc, cur) => {
      return (acc += cur[type]);
    }, 0);
  }

  // 일반적으론 유니패스 데이터에서 가지고 옴 SG에서는 유니패스 데이터가 없어 supply도 대체
  if (bidDetailData.freightType === "AIR") {
    return bidDetailData.supply;
  }
  return 0;
};

export const getTradingStatementCountryName = (
  countryList: Country[],
  country: string
) => {
  const countryData = countryList.find((n) => {
    return n.name === country;
  });
  if (countryData) {
    if (countryData.nameEN === "Republic of Korea") {
      return "korea";
    }
    return countryData.nameEN;
  }
  return "-";
};

export const getPartnerNameOrBRNNumber = (
  invoiceType: InvoiceType,
  bidDetailData: TrelloBidDetail,
  type: "BRNNumber" | "name"
) => {
  if (invoiceType === "refund") {
    const domesticPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_KOREA_PARTNER_ID ||
        v.partner?.id === FARECON_DOMESTIC_PARTNER_ID
      );
    });

    const foreignPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID ||
        v.partner?.id === GLOBELINKSHA_PARTNER_ID ||
        v.partner?.id === GLOBELINK_SHANGHAI_FCL_PARTNER_ID ||
        v.partner?.id === FARECON_FOREIGN_PARTNER_ID
      );
    });

    // 국내와 해외 둘 다 있을 경우는 국내가 우선이다.
    // 두개 이상이 있을 때 맨 처음 파트너가 표시되어야 한다.
    if (domesticPartners.length > 0) {
      return type === "name"
        ? domesticPartners[0].partner.name
        : domesticPartners[0].partner.BRNNumber;
    }

    if (foreignPartners.length > 0) {
      return type === "name"
        ? foreignPartners[0].partner.name
        : foreignPartners[0].partner.BRNNumber;
    }
  }

  if (type === "name") {
    return bidDetailData.team.isPrivate
      ? bidDetailData.user.company
      : bidDetailData.team.company;
  }

  return bidDetailData.team.BRN || "";
};

export const REFUND_FREIGHT_FEE_ITEM: TradingStatementElem = {
  key: "bookingCommission",
  item: "BOOKING COMMISSION",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "USD",
  itemPrice: 1000,
  unitPrice: 1,
  itemUnitMeasurement: "R.TON",
  isTaxExempt: false,
};

export const WAREHOUSE_RECEIPT_FEE_ITEM: TradingStatementElem = {
  key: "warehouseReceiptFee",
  item: "창고료",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "KRW",
  itemPrice: 1,
  unitPrice: 1,
  itemUnitMeasurement: "B/L",
  isTaxExempt: false,
};

export const changeFeeDataStringValueToNumber = (v: InvoiceDataFeeItem[]) => {
  if (v) {
    const feeData = v.map((n) => {
      return {
        ...n,
        amount: n.amount ? Number(n.amount) : 0,
        itemPrice:
          typeof n.itemPrice === "number"
            ? n.itemPrice
            : Number(n.itemPrice.replace(/,/g, "")),
        unitPrice:
          typeof n.unitPrice === "number"
            ? n.unitPrice
            : Number(n.unitPrice.replace(/,/g, "").replace(".00", "")),
        note: n.note,
      };
    });
    return feeData;
  }
  return [];
};

export const getWeightOfInvoiceData = (
  string: string,
  freightType: FreightType
) => {
  if (freightType === "AIR") {
    const slashIndex = getKgsStringIndex(string);
    return string.substring(0, slashIndex).replace(",", "");
  }
  const slashIndex = getSlashStringIndex(string);
  return string.substring(slashIndex + 2, string.length - 4).replace(",", "");
};

export const getCbmOfInvoiceData = (string: string) => {
  const cbmIndex = getCbmStringIndex(string);
  return string.substring(0, cbmIndex);
};

export const getInvoiceTypeKR = (invoiceType: string) => {
  switch (invoiceType) {
    case "invoice":
      return "거래명세서";
    case "refund":
      return "부킹 커미션 명세서";
    case "warehouseReceipt":
      return "창고료 명세서";
    case "etcDeposit":
      return "기타입금 명세서";
    default:
      return "-";
  }
};

export const getTotalTaxExemptPriceOfFeeData = (
  feeData: TradingStatementElem[]
) => {
  const taxExemptPriceOfFeeData = feeData.reduce(
    (accumulator, currentValue) => {
      if (currentValue.isTaxExempt) {
        return accumulator + Number(currentValue.itemPrice);
      }
      return accumulator;
    },
    0
  );

  return taxExemptPriceOfFeeData;
};

export const getDefaultFeeData = ({
  invoiceType,
  invoiceData,
  feeDataType,
  quotationsUserFeeData,
}: {
  invoiceType: InvoiceType;
  invoiceData: InvoiceData | undefined;
  feeDataType:
    | "freightFee"
    | "domesticFee"
    | "localFee"
    | "inlandFee"
    | "taxFee"
    | "otherFee";
  quotationsUserFeeData: {
    freightFee: TradingStatementElem[];
    domesticFee: TradingStatementElem[];
    localFee: TradingStatementElem[];
    inlandFee: TradingStatementElem[];
    otherFee: TradingStatementElem[];
    taxFee: TradingStatementElem[];
  };
}) => {
  // 인보이스 데이터가 오는 경우 string으로 된 데이터를 number로 변경해준다.
  if (invoiceData) {
    return changeFeeDataStringValueToNumber(invoiceData[feeDataType]);
  }

  if (invoiceType === "refund") {
    if (feeDataType === "freightFee") {
      return [REFUND_FREIGHT_FEE_ITEM];
    }
    return [];
  }

  if (invoiceType === "warehouseReceipt") {
    if (feeDataType === "otherFee") {
      return [WAREHOUSE_RECEIPT_FEE_ITEM];
    }
    return [];
  }

  if (invoiceType === "etcDeposit") {
    return [];
  }

  return quotationsUserFeeData[feeDataType];
};

export const getInvoiceExchangeRateList = ({
  invoiceType,
  exchangeRateData,
  invoiceData,
}: {
  invoiceType: InvoiceType;
  exchangeRateData: ExchangeRate[];
  invoiceData: InvoiceData | undefined;
}) => {
  if (invoiceType === "refund") {
    return exchangeRateData.map((v) => {
      return v.currency === "USD" ? { ...v, rate: 1000 } : v;
    });
  }

  if (invoiceData) {
    return exchangeRateData.map((v) => {
      return Object.keys(invoiceData.currencyJson).includes(v.currency)
        ? {
            ...v,
            rate: invoiceData.currencyJson[v.currency],
          }
        : v;
    });
  }

  return exchangeRateData;
};
