import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  DirtyShipmentItem,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

export default function useItemUpdateRequest({
  shipmentDetail,
  consolidationExporterGroupId,
  formData,
  dirtyFields,
}: {
  shipmentDetail: AdminBidDetail;
  consolidationExporterGroupId?: number;
  formData: Partial<ShipmentItem>[];
  dirtyFields: {
    bidItems?: DirtyShipmentItem[] | undefined;
  };
}) {
  const history = useHistory();

  const queryClient = useQueryClient();

  const [showsQuotationResetModal, setShowsQuotationResetModal] =
    useState(false);

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    setErrorMessage,
    setSuccessMessage,
  } = useUploadResponseSnackBar();

  /** form 데이터에서 수정된(dirty값이 true인) 항목만 추출  */
  function getFormItemWithDirty(
    formItem: Partial<ShipmentItem>,
    dirtyFormItem: DirtyShipmentItem
  ) {
    if (!dirtyFormItem) return undefined;

    let formItemWithDirty: Partial<ShipmentItem> = {};

    for (const dirtyFormKey in dirtyFormItem) {
      if (
        dirtyFormKey in formItem &&
        dirtyFormItem[dirtyFormKey as keyof ShipmentItem] === true
      ) {
        formItemWithDirty = {
          ...formItemWithDirty,
          [dirtyFormKey]: formItem[dirtyFormKey as keyof ShipmentItem],
        };
      }
    }

    /** 수정한 값이 없다면 undefined를 리턴 */
    if (Object.keys(formItemWithDirty).length === 0) return undefined;

    return formItemWithDirty;
  }

  const getRequestPayload = () => {
    const shipmentItemIdList =
      shipmentDetail.serviceType === "consolidation"
        ? shipmentDetail.bidItems
            .filter((item) => {
              return item.exporterGroup === consolidationExporterGroupId;
            })
            .map((item) => item.id)
        : shipmentDetail.bidItems.map((item) => item.id);

    const formItemIdList = formData.map((item) => item.id);

    const deleteShipmentItemIdList = shipmentItemIdList.filter(
      (value) => !formItemIdList.includes(value)
    );

    const newFormList = formData.reduce((acc, cur, index) => {
      /** 새로운 항목을 추가하거나 LCL, AIR에서 포장별 <=> 충 부피/중량 변경 시 id가 존재하지 않음 */
      if (!cur.id) {
        acc = [...acc, cur];
      }

      /** 기존 화물을 수정하는 경우  */
      if (
        cur.id &&
        shipmentItemIdList.includes(cur.id) &&
        dirtyFields?.bidItems
      ) {
        const newFormItem = getFormItemWithDirty(
          cur,
          dirtyFields.bidItems[index]
        );

        if (newFormItem) {
          if (shipmentDetail.serviceType === "consolidation") {
            acc = [
              ...acc,
              {
                ...newFormItem,
                id: cur.id,
                consolidationItemId: cur.consolidationItemId,
              },
            ];
          } else {
            acc = [...acc, { ...newFormItem, id: cur.id }];
          }
        }
      }

      return acc;
    }, [] as Partial<ShipmentItem>[]);

    return { deleteShipmentItemIdList, newFormList };
  };

  const { mutate: updateShipmentItemWithoutRecalculation } =
    ADMIN_BID_QUERY.useUpdateShipmentItemWithoutRecalculation();

  const { mutate: checkShipmentResetByItemUpdate } =
    ADMIN_BID_QUERY.useCheckShipmentResetByItemUpdate();

  const handleRecalculationCheckAndUpdate = () => {
    const { deleteShipmentItemIdList, newFormList } = getRequestPayload();

    if (newFormList.length === 0 && deleteShipmentItemIdList.length === 0) {
      setErrorMessage("수정한 내용이 없습니다.");
      setShowsErrorSnackBar(true);
      return;
    }

    const handleUpdateShipmentItemWithoutRecalculation = () => {
      updateShipmentItemWithoutRecalculation(
        {
          pathParams: { shipmentId: shipmentDetail.id },
          bidItem: newFormList,
          deleteBidItemIds: deleteShipmentItemIdList,
          exporterGroup: consolidationExporterGroupId,
        },
        {
          onSuccess: () => {
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentDetail.id,
              })
            );
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );
    };

    /** 확정 견적이 없는 경우 체크 여부 없이 강제 수정을 진행 */
    if (shipmentDetail.quotationsUser.length === 0) {
      handleUpdateShipmentItemWithoutRecalculation();
      return;
    }

    checkShipmentResetByItemUpdate(
      {
        pathParams: { shipmentId: shipmentDetail.id },
        bidItem: newFormList,
        deleteBidItemIds: deleteShipmentItemIdList,
        exporterGroup: consolidationExporterGroupId,
      },
      {
        onSuccess: ({ data }) => {
          /** 응답값이 true면 견적 리셋 방법 선택 모달 오픈 */
          if (data) {
            setShowsQuotationResetModal(true);
            return;
          }

          /** 응답값이 false면 견적 리셋없이 바로 수정 */
          handleUpdateShipmentItemWithoutRecalculation();
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  };

  const handleShipmentItemWithoutRecalculationUpdate = (
    type: "quotationReset" | "quotationUpdate"
  ) => {
    const { deleteShipmentItemIdList, newFormList } = getRequestPayload();

    updateShipmentItemWithoutRecalculation(
      {
        pathParams: { shipmentId: shipmentDetail.id },
        bidItem: newFormList,
        deleteBidItemIds: deleteShipmentItemIdList,
        exporterGroup: consolidationExporterGroupId,
      },

      {
        onSuccess: () => {
          if (type === "quotationUpdate") {
            history.push(`/bid/change-user-quotation/${shipmentDetail.id}`);
            return;
          }

          history.push(
            `/bid/applyBid/${shipmentDetail.freightType}/${shipmentDetail.id}`
          );
          return;
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  };

  return {
    handleRecalculationCheckAndUpdate,
    handleShipmentItemWithoutRecalculationUpdate,
    UploadResponseSnackBar,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
  };
}
