import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Grid } from "@mui/material";

import {
  ShipmentItem,
  ShipmentItemVolumeUnit,
  ShipmentItemWeightUnit,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  packageOptionList,
  SIZE_UNIT_OPTION_LIST,
  WEIGHT_UNIT_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";
import NumericFormatWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/NumericFormatWithReactHookForm";
import SelectWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SelectWithReactHookForm";
import SwitchWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

function LCLAndAIRItemForm({
  isTotalVolumeAndWeight,
  shipmentItemList,
  onShipmentItemWithoutRecalculationUpdate,
}: {
  isTotalVolumeAndWeight: boolean;
  shipmentItemList: ShipmentItem[];
  onShipmentItemWithoutRecalculationUpdate: () => void;
}) {
  const [isTotalVolumeAndWeightState, setIsTotalVolumeAndWeightState] =
    useState(isTotalVolumeAndWeight);

  const { control, handleSubmit, setValue, watch } = useFormContext<{
    bidItems: Partial<ShipmentItem>[];
  }>();

  const { remove, append, fields } = useFieldArray({
    control,
    name: "bidItems",
  });

  const handleRemoveForm = (formIndex: number) => {
    remove(formIndex);
  };

  const handleProductsInfoTypeChange = () => {
    setIsTotalVolumeAndWeightState((prev) => {
      /** 포장별 => 총 부피중량으로로 변경하는 경우 */
      if (!isTotalVolumeAndWeight && !prev) {
        const newFieldList = watch("bidItems").map((formItem) => {
          return {
            isDangerous: formItem.isDangerous,
            canStack: formItem.canStack,
            packingType: formItem.packingType,
            weight: 0,
            cbm: 0,
            volumeUnit: "CBM" as ShipmentItemVolumeUnit,
            mode: "total",
            weightUnit: "KG" as ShipmentItemWeightUnit,
            /** 복수인 경우 품목명은 하나로 합쳐준다. */
            name: shipmentItemList
              .map((item) => {
                return item.name;
              })
              .toString(),
          };
        });
        /** 총 부피중량일때는 하나의 화물정보만 받는다. */
        setValue("bidItems", [newFieldList[0]]);

        return !prev;
      }

      /** 총 부피중량 => 포장별로 변경하는 경우 */
      if (isTotalVolumeAndWeight && prev) {
        const newFieldList = watch("bidItems").map((formItem) => {
          return {
            isDangerous: formItem.isDangerous,
            canStack: formItem.canStack,
            weightUnit: formItem.weightUnit,
            packingType: formItem.packingType,
            vertical: formItem.vertical,
            horizontal: formItem.horizontal,
            height: formItem.height,
            quantity: formItem.quantity,
            weight: 0,
            volumeUnit: "CM" as ShipmentItemVolumeUnit,
            mode: "item",
            name: formItem.name,
          };
        });

        setValue("bidItems", newFieldList);

        return !prev;
      }

      /** 다시 원래 타입으로 돌아가는 경우 */
      setValue("bidItems", shipmentItemList);

      return !prev;
    });
  };

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      component="form"
      onSubmit={handleSubmit(onShipmentItemWithoutRecalculationUpdate)}
    >
      {fields.map((LCLAndAIRItem, index) => {
        return (
          <Grid container spacing={1} key={LCLAndAIRItem.id}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={3}>
                <SelectWithReactHookForm
                  name={`bidItems.${index}.packingType`}
                  control={control}
                  label="포장타입"
                  options={packageOptionList.map((v) => {
                    return v.value;
                  })}
                  fullWidth
                  required={true}
                />
              </Grid>

              <Grid item xs={3}>
                <TextFieldWithReactHookForm
                  name={`bidItems.${index}.name`}
                  control={control}
                  label="품명"
                  required={true}
                />
              </Grid>

              <Grid item xs={3}>
                <SwitchWithReactHookForm
                  label="위험물"
                  control={control}
                  name={`bidItems.${index}.isDangerous`}
                  defaultValue={false}
                />
              </Grid>

              <Grid item xs={3}>
                <SwitchWithReactHookForm
                  label="2단 적재"
                  control={control}
                  name={`bidItems.${index}.canStack`}
                  defaultValue={false}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              {!isTotalVolumeAndWeightState && (
                <Grid item xs={2}>
                  <NumericFormatWithReactHookForm
                    name={`bidItems.${index}.horizontal`}
                    control={control}
                    label="가로"
                    required={true}
                  />
                </Grid>
              )}

              {!isTotalVolumeAndWeightState && (
                <Grid item xs={2}>
                  <NumericFormatWithReactHookForm
                    name={`bidItems.${index}.vertical`}
                    control={control}
                    label="세로"
                    required={true}
                  />
                </Grid>
              )}

              {!isTotalVolumeAndWeightState && (
                <Grid item xs={2}>
                  <NumericFormatWithReactHookForm
                    name={`bidItems.${index}.height`}
                    control={control}
                    label="높이"
                    required={true}
                  />
                </Grid>
              )}

              {!isTotalVolumeAndWeightState && (
                <Grid item xs={2}>
                  <SelectWithReactHookForm
                    name={`bidItems.${index}.volumeUnit`}
                    control={control}
                    label="부피 단위"
                    options={SIZE_UNIT_OPTION_LIST.map((option) => {
                      return option.value.toUpperCase();
                    })}
                    fullWidth
                    required={true}
                  />
                </Grid>
              )}

              {isTotalVolumeAndWeightState && (
                <Grid item xs={2}>
                  <NumericFormatWithReactHookForm
                    name={`bidItems.${index}.cbm`}
                    control={control}
                    label="CBM"
                    required={true}
                  />
                </Grid>
              )}

              <Grid item xs={1}>
                <NumericFormatWithReactHookForm
                  name={`bidItems.${index}.weight`}
                  control={control}
                  label="중량"
                  required={true}
                />
              </Grid>

              <Grid item xs={2}>
                <SelectWithReactHookForm
                  name={`bidItems.${index}.weightUnit`}
                  control={control}
                  label="중량 단위"
                  options={WEIGHT_UNIT_OPTION_LIST.map((v) => {
                    return v.value;
                  })}
                  fullWidth
                  required={true}
                />
              </Grid>

              {!isTotalVolumeAndWeightState && (
                <Grid item xs={1}>
                  <NumericFormatWithReactHookForm
                    name={`bidItems.${index}.quantity`}
                    control={control}
                    label="수량"
                    required={true}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item>
              <Button
                disabled={fields.length === 1}
                color="error"
                onClick={() => handleRemoveForm(index)}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
        );
      })}

      {!isTotalVolumeAndWeightState && (
        <Grid item>
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              append({
                name: "",
                mode: "item",
                isDangerous: false,
                canStack: false,
                volumeUnit: "CM",
                weightUnit: "KG",
                packingType: "boxes",
                vertical: 0,
                horizontal: 0,
                height: 0,
                weight: 0,
                quantity: 1,
              })
            }
          >
            추가
          </Button>
        </Grid>
      )}

      <Grid item>
        <Button variant="outlined" onClick={handleProductsInfoTypeChange}>
          {isTotalVolumeAndWeightState
            ? "포장별로 변경"
            : "총 부피/중량으로 변경"}
        </Button>
      </Grid>

      <Grid item>
        <Button variant="contained" type="submit">
          수정
        </Button>
      </Grid>
    </Grid>
  );
}

export default LCLAndAIRItemForm;
